<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">

		<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{ span: 12, offset: 0 }" :xl="{ span: 6, offset: 2 }" class="col-form">
				<h1 class="mb-15">Войти</h1>
				<h5 class="font-regular text-muted">Введите email и пароль</h5>

				<!-- Sign In Form -->
				<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit"
					:hideRequiredMark="true">
					<a-form-item class="mb-10" label="Email" :colon="false">
						<a-input v-decorator="[
							'email',
							{ rules: [{ required: true, message: 'Пожалуйста, введите ваш Email' }] },
						]" placeholder="Email" />
					</a-form-item>
					<a-form-item class="mb-5" label="Password" :colon="false">
						<a-input v-decorator="[
							'password',
							{ rules: [{ required: true, message: 'Пожалуйста, введите ваш пароль!' }] },
						]" type="password" placeholder="Password" />
					</a-form-item>
					<a-form-item class="mb-10">
						<a-switch v-model="rememberMe" /> Запомнить меня
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
							ВОЙТИ
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->
				<!--
				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in"
						class="font-bold text-dark">Sign Up</router-link></p> -->
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/Telegram_2019_Logo.svg" alt="Telegram logo" style="padding: 8rem;">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>

	</div>
</template>

<script>
import { notification } from 'ant-design-vue';
import axios from 'axios';

export default ({
	data() {
		return {
			// Binded model property for "Sign In Form" switch button for "Remember Me" .
			rememberMe: true,
		}
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	methods: {
		openNotification(placement, status, message) {
			const api = notification.open;
			api({
				message: status,
				description: message,
				placement
			});
		},
		async submitLoginData(values) {
			try {
				//console.log(this.ticket);
				let prequest = {
					email: values.email,
					password: values.password // NOTE: try with lowercase d for golang BindJson
				}
				const response = await axios.post("api/v1/login", prequest);
				console.log(response);
				if (response.data.status === 'success') {
					this.openNotification('topRight', "Успешный вход", "Успешно вошли под пользователем " + values.email);
					localStorage.setItem("loggedOn", true);
					localStorage.setItem("loggedUsername", values.email);
					this.$router.push({ path: '/dashboard' });
				}
				else {
					this.openNotification('topRight', "Ошибка входа", "Произошла ошибка входа для пользователя" + values.email + " " + response.data.error);
					localStorage.setItem("loggedOn", "false");
				}
				console.log(response.data);
			}
			catch (error) {
				console.log(error);
				this.openNotification('topRight', "Ошибка входа", "Произошла ошибка входа для пользователя " + values.email + " " + " backend-сервер не доступен");
				localStorage.setItem("loggedOn", "false");
			}
		},
		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					console.log('Received values of form: ', values);
				}
				this.submitLoginData(values);
			});
		},
	},
})

</script>

<style lang="scss">
body {
	background-color: #ffffff;
}
</style>
